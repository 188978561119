import ApiService from "../services/api.service";
const RegulationsService = {
  GetList(
    Search,
    Lang,
    SortColumn,
    OrderType,
    Offset,
    PageNumber,
    PageLimit,
    IsStartPageFromZero
  ) {
    return ApiService.get(
      `/Regulations/GetList?Search=${Search}&Lang=${Lang}&SortCoumn=${SortColumn}&OrderType=${OrderType}&Offset=${Offset}&PageNumber=${PageNumber}&PageLimit=${PageLimit}&IsStartPageFromZero=${IsStartPageFromZero}`
    );
  },
  GetChildList(
    Search,
    Lang,
    SortColumn,
    OrderType,
    Offset,
    PageNumber,
    PageLimit,
    IsStartPageFromZero,
    parentID
  ) {
    return ApiService.get(
      `/Regulations/GetChildList?Search=${Search}&Lang=${Lang}&SortCoumn=${SortColumn}&OrderType=${OrderType}&Offset=${Offset}&PageNumber=${PageNumber}&PageLimit=${PageLimit}&IsStartPageFromZero=${IsStartPageFromZero}&parentID=${parentID}`
    );
  },
  Get(id, lang) {
    return ApiService.get(`/Regulations/Get?id=${id}&lang=${lang}`);
  },
  Update(data) {
    return ApiService.post(`/Regulations/Update`, data);
  },
};
export default RegulationsService;
